import { memo } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebook,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import BrandsSlider from 'components/BrandsSlider'
import Year from '../../../common/src/components/year'

export default memo(() => {
  return (
    <>
      <div id="bottom" className="footer-bg container-fluid mt-5">
        <div className="row justify-content-center bg-footer-img">
          <div className="col-xl-9 col-lg-10 col-md-12 p-0">
            <div className="row pt-5 pb-5 m-0">

              <Link to="/" className="col-lg-3 col-md-3 logo pr-4">
                <StaticImage
                  imgClassName="img-fluid"
                  src="../images/logo.png"
                  alt="air logo"
                  placeholder="none"
                  width={200}
                />
              </Link>


              <div className="col">
                <h5>PRODUCTS</h5>
                <ul className="text-left pl-0">
                  <li><Link to="/bpm-analyzer.html">BPM Analyzer</Link></li>
                  <li><Link to="/express.html">Express</Link></li>
                  <li><Link to="/studio.html">Studio</Link></li>
                  <li><Link to="/fusion.html">Fusion</Link></li>
                </ul>
              </div>


              <div className="col">
                <h5>SUPPORT</h5>
                <ul className="text-left pl-0">
                  <li><a href="http://support.mixmeister.com" target="_blank">Contact Support</a></li>
                  <li><Link to='/refundpolicy'>Refund Policy</Link></li>
                </ul>
              </div>


              <div className="col">
                <h5>COMPANY</h5>
                <ul className="text-left pl-0">
                  <li><Link to="/about-us.html">About Us</Link></li>
                  {/* <li><a href="https://www.plugivery.com/about/dealers/" target="_blank">Dealers</a></li> */}
                </ul>
              </div>


              {/* <div className="col">
                <h5>FOLLOW US</h5>

                <ul className="social-list row pl-0">
                  <li className="col-auto pl-3 p-1">
                    <a href="https://www.facebook.com/MixMeister" target="_blank">
                      <FontAwesomeIcon
                        icon={faFacebook}
                        style={{
                          width: 'auto',
                          height: '28px'
                        }}
                      />
                    </a>
                  </li>
                  <li className="col-auto p-1">
                    <a href="https://twitter.com/MixMeister" target="_blank">
                      <FontAwesomeIcon
                        icon={faTwitter}
                        style={{
                          width: 'auto',
                          height: '28px'
                        }}
                      />
                    </a>
                  </li>
                  <li className="col-auto p-1">
                    <a href="https://www.youtube.com/user/MixMeisterSupport" target="_blank">
                      <FontAwesomeIcon
                        icon={faYoutube}
                        style={{
                          width: 'auto',
                          height: '28px'
                        }}
                      />
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>

          </div>
        </div>
      </div>
      <footer>
        <div className="container-fluid bglight">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-10 col-xl-8 p-0 pt-5">

              <div id="inmusic-logos-scroller">
                <div className="inmusic-logos-scroller-title text-center">
                  <StaticImage
                    imgClassName="invert"
                    src="https://d1jtxvnvoxswj8.cloudfront.net/wysiwyg/global/logo-inmusic-wht.png"
                    placeholder="blurred"
                    height={20}
                    alt="InMusic logo"
                  />
                  <p>Home of the world's premier music and audio technology brands</p>
                  <hr />
                </div>
                <div className="m-md-4 m-sm-0">
                  <BrandsSlider />
                </div>
              </div>

              <br/><br/><br/>


              <div className="row text-center m-0">
                <div className="col copyright">
                  <p>© <Year /> inMusic Brands Inc. • All Rights Reserved.</p>
                  <p>
                    <Link to="/privacy.html" target="_blank">
                      Privacy Policy
                    </Link>
                    &nbsp;·&nbsp;
                    <Link to="/terms-of-use.html" target="_blank">
                      Terms of Use
                    </Link>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </footer>
    </>
  )
})
