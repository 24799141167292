import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

const BrandSlide = ({ link, localImage }) => (
  <a href={link} target="_blank" >
    <GatsbyImage
      image={getImage(localImage)}
      loading="eager"
      alt=""
    />
  </a>
)

const BrandSlider = () => {
  const { brands } = useStaticQuery(graphql`
      query {
          brands: allBrandsJson {
              nodes {
                  link,
                  localImage {
                      childImageSharp {
                          gatsbyImageData(height: 25, layout: FIXED, placeholder: TRACED_SVG)
                      }
                  }
              }
          }
      }
  `)

  return (
    <Slider
      className="inmusic-logos-scroller invert"
      infinite={true}
      centerMode={true}
      variableWidth={true}
      speed={300}
    >
      {
        brands.nodes.map((brand) => (
          <BrandSlide
            {...brand}
            key={brand.link}
          />
        ))
      }
    </Slider>
  )
}

export default BrandSlider
