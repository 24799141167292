import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faShoppingCart } from "@fortawesome/free-solid-svg-icons"
import CartButton from 'common/components/CartButton'
import useStickyNav from 'common/hooks/useStickyNav'
import { BiSupport } from 'react-icons/bi'

export default () => {
  const {
    isSticky,
    navRef,
    toggleNav,
    navVisible
  } = useStickyNav()

  return (
    <div style={{ minHeight: '56px' }}>
      <div id="nav-bg" className={`container-fluid accent-btm-bdr ${isSticky ? 'fixed-top': ''}`} ref={navRef}>
        <div className="row justify-content-center align-items-center d-flex">
          <div className="col-lg-11 col-md-12 p-0 align-items-center">
            <nav className="navbar navbar-expand-md">
              <Link
                className="navbar-brand mr-0 mr-md-5 d-flex"
                to="/"
                // style={{ maxWidth: '79px'}}
              >
                <StaticImage
                  src="../images/logo.png"
                  alt="logo"
                  placeholder="none"
                  className="mt-1"
                />
              </Link>
              
              <div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={toggleNav}
                >
                    <span className="navbar-toggler-icon">
                      <StaticImage
                        src="../images/toggler.svg"
                        alt="nav menu toggler"
                        placeholder="blurred"
                        layout="fixed"
                        width={30}
                        height={30}
                      />
                    </span>
                </button>

                <CartButton className="d-inline d-md-none">
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    size="lg"
                    title="Shopping cart"
                  />
                </CartButton>
              </div>

              <div className={`collapse navbar-collapse ${navVisible ? 'show' : ''}`}
                   id="navbarNav">

                <ul className="navbar-nav align-items-center">
                  <li className="nav-item text-center">
                    <Link
                      to="/bpm-analyzer.html"
                      className="nav-link"
                      activeClassName="active"
                    >
                      BPM Analyzer
                    </Link>
                  </li>
                  <li className="nav-item text-center">
                    <Link
                      to="/express.html"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Express
                    </Link>
                  </li>
                  <li className="nav-item text-center">
                    <Link
                      to="/studio.html"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Studio
                    </Link>
                  </li>
                  <li className="nav-item text-center">
                    <Link
                      to="/fusion.html"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Fusion
                    </Link>
                  </li>
                </ul>

                <ul className="navbar-nav align-items-center ml-auto">
                  <li className="nav-item text-center d-none d-md-inline">
                    <CartButton>
                      <FontAwesomeIcon
                        icon={faShoppingCart}
                        size="lg"
                        title="Shopping cart"
                      />
                    </CartButton>
                  </li>
                  <li className="nav-item text-center">
                    <a
                      className="nav-link"
                      href="https://mixmeister.onfastspring.com/account"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        size="lg"
                        title="Account"
                        className="d-none d-md-inline"
                      />
                      <span
                        className="d-block d-md-none"
                      >
                          Account
                        </span>
                    </a>
                  </li>
                  <li className="nav-item text-center">
                    <a
                      className="nav-link"
                      href="https://support.mixmeister.com/"
                      target="_blank"
                      title="Contact Support"
                    >
                      <BiSupport size='1.5rem' />
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}
