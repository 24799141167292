import FastSpringStoreProvider from 'common/modules/FastSpringStore/FastSpringStore.provider';
import MainLayout from './src/layouts/MainLayout';

export const wrapRootElement = ({ element }) => (
  <FastSpringStoreProvider>
    {element}
  </FastSpringStoreProvider>
);

export const wrapPageElement = ({ element }) => (
  <MainLayout>
    {element}
  </MainLayout>
);
